import React, { useEffect, useMemo, useState } from 'react';
import WatchApi from '../apis/WatchApi';
import Poster from '../components/Poster/Poster';
import VideoPlayer from '../components/VideoPlayer/VideoPlayer';
import getParams from '../functions/getParams';

const PlayerScreen: React.FC<any> = (props) => {

    const params = getParams();

    const [video, setVideo] = useState(params);

    const watch = WatchApi.getPlayer(params.id);

    useEffect(() => {

        if (params.source === "test") {

            setVideo({
                source: "external",
                uri: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
                type: "mp4",
                title: "Test Video",
            });

        } if (params.source === "testPoster") {

            setVideo({
                source: "poster",
                poster: { fileId: "#" },
                title: "Test Poster",
            });

        } else if (params.source === "watch") {

            watch.run({
                onComplete: (response) => {

                    setVideo({
                        ...video,
                        ...response
                    });

                }
            });

        }

    }, [params.id]);

    return useMemo(() => {

        return (

            <>

                {video &&
                    <>


                    {(video.source === "youtube" || video.source === "facebook" || video.source === "external") && video.uri &&
                            <VideoPlayer video={video} />
                        }

                        {(video.source === "poster") &&
                            <Poster video={video} />
                        }

                    </>
                }

            </>

        )

    }, [video]);

}


export default PlayerScreen;
