import { UiView } from '@dex/bubl-ui';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import Controllers from './Controllers';
import PlayPause from './PlayPause';

import styles from './VideoPlayer.module.scss'

const mouseTimer: any = null;

const VideoPlayer: React.FC<VideoPlayerProps> = ({ video, ...rest }) => {

    let { source, uri, autoplay } = video;

    const [progress, setProgress]: any = useState(0);
    const [seek, setSeek]: any = useState(0);
    const [loaded, setLoaded] = useState(0);
    const [volume, setVolume]: any = useState(0.5);
    const [playing, setPlaying]: any = useState(autoplay);
    const [muted, setMuted] = useState(false);
    const [playbackRate, setPlaybackRate]: any = useState(1);
    const [buffer, setBuffer] = useState(false);
    const [hideControls, setHideControls] = useState(false);

    const playerRef: any = useRef(null);
    const playerContainerRef: any = useRef(null);
    const controllerRef: any = useRef(null);

    const handlePlayPause = () => setPlaying((prev: any) => !prev)

    const handleRewind = () => seekTo(playerRef?.current?.getCurrentTime() - 10);

    const handleForward = () => seekTo(playerRef?.current?.getCurrentTime() + 10);

    const handleMute = () => setMuted(!muted);

    const handleVolume = (value: any) => {

        setVolume(parseFloat((value / 100).toFixed(2)));

        if (value === 0) setMuted(true);
        else setMuted(false);
    }

    const handlePlaybackRate = (value: any) => setPlaybackRate(value);

    const handleFullscreen = () => screenfull.toggle(playerContainerRef?.current);

    const handleProgress = (value: any) => {
        setSeek(value.played);
        setProgress(value.played)
        setLoaded(value.loaded)
    };

    const handleProgressChange = (value: any) => {

        const time = parseFloat((value / 100).toFixed(2));

        setSeek(time);

        seekTo(time);
    };

    const seekTo = (time: any) => playerRef?.current?.seekTo(time);

    const handleKeyPress = useCallback((e: any) => {

        const currentTime = playerRef.current.getCurrentTime();

        if (e.which === 39) {
            seekTo(currentTime + 5);
        }

        if (e.which === 37) {
            seekTo(currentTime - 5);
        }

        if (e.which === 32) {
            handlePlayPause();
        }

    }, [playerRef, playing])


    const handleOnPause = () => {

        if (source !== 'pixellot') return;

        const el: any = getElement();

        el.path.setAttribute('d', "M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M27,46h-8V14h8V46z M41,46h-8V14h8V46z");

        el.svg.appendChild(el.path);
        el.button.append(el.svg)

        el.wrapper.append(el.button);

    }

    const handleOnPlay = () => {

        if (source !== 'pixellot') return;

        const el: any = getElement();

        el.path.setAttribute('d', "M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M45.563,30.826l-22,15C23.394,45.941,23.197,46,23,46c-0.16,0-0.321-0.038-0.467-0.116C22.205,45.711,22,45.371,22,45V15c0-0.371,0.205-0.711,0.533-0.884c0.328-0.174,0.724-0.15,1.031,0.058l22,15C45.836,29.36,46,29.669,46,30S45.836,30.64,45.563,30.826z");

        el.svg.appendChild(el.path);
        el.button.append(el.svg)

        el.wrapper.append(el.button);
    }

    const currentTime = playerRef.current ? playerRef.current.getCurrentTime() : "00:00";
    const duration = playerRef.current ? playerRef.current.getDuration() : "00:00";

    const elapsedTime = format(currentTime);
    const totalDuration = format(duration);

    const handleMouseEnter = useCallback(() => {

        if (mouseTimer) clearTimeout(mouseTimer);

        setHideControls(false);

    }, [mouseTimer]);

    const handleMouseLeave = useCallback(() => {

        if (mouseTimer) clearTimeout(mouseTimer);

        setTimeout(() => {

            setHideControls(true);

        }, 2000);

    }, [mouseTimer]);

    return (

        <>

            <UiView
                forwardedRef={playerContainerRef}
                className={[styles.container, (playing && hideControls) ? 'hideControls' : ""]}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >

                <UiView
                    id={"playerWrapper"}
                    className={styles.playerWrapper}
                    tabIndex={1}
                    onKeyDown={handleKeyPress}
                // onClick={handlePlayPause}
                >

                    {buffer && <Buffer />}

                    <UiView
                        className={source === 'youtube' ? styles.ytWrapper : source === 'facebook' ? styles.fbWrapper : styles.otherWrapper}
                    >

                        {/* @ts-ignore */}
                        <ReactPlayer
                            ref={playerRef}
                            url={uri}
                            muted={muted}
                            width="100%"
                            height="100%"
                            playing={playing}
                            volume={volume}
                            playbackRate={playbackRate}
                            config={{
                                file: {
                                    attributes: {
                                        crossOrigin: 'anonymous'
                                    },
                                    hlsOptions: {
                                        capLevelOnFPSDrop: true,
                                    }
                                }
                            }}
                            onContextMenu={(e: any) => e.preventDefault()}
                            onBuffer={() => {
                                setBuffer(true);
                            }}
                            onBufferEnd={() => {
                                setBuffer(false);
                            }}
                            onProgress={handleProgress}
                            onPause={handleOnPause}
                            onPlay={handleOnPlay}
                        />

                    </UiView>

                </UiView>

                <PlayPause
                    onHandlePlayPause={handlePlayPause}
                />

                <Controllers
                    ref={controllerRef}
                    title={"Video Title"}
                    elapsedTime={elapsedTime}
                    totalDuration={totalDuration}
                    hideControls={playing && hideControls}
                    settings={{
                        progress: progress,
                        seek: seek,
                        loaded: loaded,
                        muted: muted,
                        playing: playing,
                        volume: volume,
                        playbackRate: playbackRate,
                        disableRewind: true,
                        disableForward: true,
                        buffer: buffer
                    }}
                    onHandlePlayPause={handlePlayPause}
                    onHandleFullscreen={handleFullscreen}
                    onHandleVolume={handleVolume}
                    onHandleMute={handleMute}
                    onHandleProgress={handleProgressChange}
                    onHandleRewind={handleRewind}
                    onHandleForward={handleForward}
                    onHandlePlaybackRate={handlePlaybackRate}
                />

            </UiView>

        </>
    )
}

type VideoProps = {
    source: string,
    uri: string,
    autoplay: boolean,
}

interface VideoPlayerProps {
    video: VideoProps
    [key: string]: any
}

const format = (seconds: any) => {

    if (isNaN(seconds)) return "00:00";

    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, '0');

    if (hh) return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;

    return `${mm}:${ss}`;
}

const Buffer: React.FC<any> = (props) => {

    return (
        <UiView className={styles.buffer}>
            <UiView className={styles.loader} />
        </UiView>
    )

}


const getElement = () => {

    const el = document.getElementById('playerWrapper');

    const button = document.createElement('div');
    button.className = styles.playPause;

    button.onanimationend = () => {
        button.remove();
    }

    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');

    svg.setAttribute('viewBox', '0 0 60 60');
    svg.setAttribute('x', '0');
    svg.setAttribute('y', '0');
    svg.setAttribute('style', 'enable-background:new 0 0 60 60');

    return {
        button, svg, path, wrapper: el
    }

}

export default VideoPlayer;